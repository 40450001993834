export const onInitialClientRender = () => {
  const popup = document.querySelector(".homepopup")

  if (popup !== null) {
    popup.classList.add("homepopup--active")
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, 0)

  return false
}
